@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.usageToken {
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 18px;
  padding: 6px 8px;
  color: $white;
  background: #345465;
  width: fit-content;
  align-items: center;
  border: 0;
  height: 34px;
  min-width: 34px;

  &.clickable {
    cursor: pointer;
  }

  &.miniView {
    height: auto;
  }

  .icon {
    @include svg-bold();
  }
}
